@import "../../../styles/colors";
@import "../../../styles/common";

.unsetLink {
  color: unset;
  text-decoration: unset;
}

.link {
  background: none;
  border: none;
  display: inline;
  color: $mutedGreen;
  text-decoration: underline;

  .green {
    color: $mutedGreen;
  }

  @include hover-support() {
    &:focus,
    &:hover {
      color: $red;
      cursor: pointer;
    }
  }

  &:active {
    transform: translateY(1px);
  }

  &.red {
    color: $red;

    @include hover-support() {
      &:focus,
      &:hover {
        color: $green;
      }
    }
  }

  &.white {
    color: $white;

    @include hover-support() {
      &:focus,
      &:hover {
        color: $green;
      }
    }
  }

  &.dark {
    color: $dark;

    @include hover-support() {
      &:focus,
      &:hover {
        color: $black;
      }
    }
  }
}

.logo {
  max-width: 180px;
  width: 80%;
  margin-top: 0.2rem;
  color: white;
  display: block;

  @include desktopOnly {
    max-width: 225px;
  }

  > * {
    display: block !important;
  }
}
